<script>
	import { lightFormat } from 'date-fns';

	export let translations;
	export let requestedDate;
	export let requestedTime;
	export let options;
</script>

<div class="wrapper">
	<label class="label">
		{translations.date}
		<input
			class="input"
			type="date"
			name="requestedDate"
			min={lightFormat(new Date(), 'yyyy-MM-dd')}
			max="9999-12-31"
			bind:value={requestedDate}
			required
		/>
	</label>

	<label class="label">
		{translations.time}
		<select class="input" name="requestedTime" bind:value={requestedTime}>
			{#each options as { text, value }}
				<option {value}>{text}</option>
			{/each}
		</select>
	</label>
</div>

<style>
	.wrapper {
		display: grid;
		grid: auto / auto;
		align-content: flex-start;
		grid-gap: 24px;
	}
</style>
