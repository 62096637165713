<script>
	import ContactInputs from '$lib/ContactInputs.svelte';
	import DateInputs from '$lib/DateInputs.svelte';
	import CustomerTypeCards from '$lib/CustomerTypeCards.svelte';

	export let translations;
	export let name;
	export let email;
	export let phone;
	export let phoneCountryCode;
	export let customerType;
	export let requestedDate;
	export let requestedTime;
	export let timeOptions;
	export let serviceOptions;
	export let phoneCodeOptions;
	export let contactLink;
</script>

<div class="wrapper">
	<div class="description">
		<p class="description-big">
			{translations.startYourBooking}

			<a class="link" href={contactLink} rel="noreferrer">{translations.herePlaceholder}</a>
		</p>
	</div>

	<CustomerTypeCards options={serviceOptions} bind:customerType />

	<ContactInputs
		{translations}
		{phoneCodeOptions}
		bind:name
		bind:email
		bind:phone
		{phoneCountryCode}
	/>

	<DateInputs {translations} options={timeOptions} bind:requestedDate {requestedTime} />
</div>

<style>
	.wrapper {
		display: grid;
		grid-gap: 40px;
	}

	a {
		text-decoration: none;
		color: var(--gf-teal-dark);
	}
</style>
