<script>
	export let customerType;
	export let options = [];
</script>

<div class="CustomerTypes">
	{#each options as { text, description, icon, value }}
		<label class="label-wrapper">
			<div class="content-wrapper" class:selected={customerType === value}>
				{#if customerType === value}
					<div class="card-selected-icon">
						<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M10.2426 16.3137L6 12.071L7.41421 10.6568L10.2426 13.4853L15.8995 7.8284L17.3137 9.24262L10.2426 16.3137Z"
								fill="currentColor"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z"
								fill="currentColor"
							/>
						</svg>
					</div>
				{/if}

				<div class="icon-wrapper">
					<img src={icon} alt="Service icon" />
				</div>

				<div class="text-wrapper">
					<h3>
						{text}
					</h3>

					<p>
						{description}
					</p>
				</div>
			</div>

			<input
				class="hidden"
				type="radio"
				name="customerType"
				bind:group={customerType}
				{value}
				checked={customerType === value}
			/>
		</label>
	{/each}
</div>

<style>
	.CustomerTypes {
		display: grid;
		grid: 1fr / 1fr;
		grid-gap: 24px;
	}

	.content-wrapper {
		position: relative;
		display: grid;
		grid: 1fr / 2fr 3fr;
		border: 2px solid var(--gf-white-light);
		padding: 24px;
		grid-gap: 24px;
		border-radius: 8px;
		box-shadow: var(--box-shadow-light);
		background-color: var(--gf-white-light);
		cursor: pointer;
		box-sizing: border-box;
	}

	.content-wrapper:hover {
		border: 2px solid var(--gf-grey-dark);
	}

	.label-wrapper:active {
		-webkit-tap-highlight-color: transparent;
	}

	.selected {
		border: 2px solid var(--gf-grey-dark);
	}

	.icon-wrapper {
		display: grid;
		place-items: center;
	}

	.text-wrapper {
		max-width: 240px;
		display: grid;
		align-content: flex-start;
		grid-gap: 4px;
		padding: 8px 0;
	}

	img {
		max-width: 120px;
	}

	.hidden {
		display: none;
	}

	h3 {
		margin: 0;
		font-size: 24px;
	}

	p {
		margin: 0;
		font-size: 14px;
		font-weight: lighter;
	}

	.card-selected-icon {
		position: absolute;
		top: 6px;
		right: 6px;
		width: 32px;
		height: 32px;
		background-color: var(--gf-white-light);
	}

	@media screen and (min-width: 425px) {
		.content-wrapper {
			grid: 1fr / 130px 1fr;
		}
	}
</style>
