<script>
	import { locale } from '$utils/appwrite-client';
	import { scrollToOnClick } from '$utils/helpers';

	import { imask } from '@imask/svelte';
	import { onMount } from 'svelte';

	export let translations;
	export let phoneCodeOptions;
	export let phoneCountryCode;
	export let name;
	export let email;
	export let phone;

	onMount(() => {
		if (!phoneCountryCode)
			locale.get().then((value) => {
				selectedPhoneCode =
					phoneCodeOptions.find(({ countryCode }) => countryCode === value.countryCode)?.code ?? '';
			});
	});

	let selectedPhoneCode =
		phoneCodeOptions.find(({ code }) => code === phoneCountryCode)?.code ?? '';

	phone = phone?.replace(selectedPhoneCode, '');

	$: phoneOptions = {
		mask: `{${selectedPhoneCode}} 000 00 00 0000`,
		lazy: true
	};
</script>

<div class="wrapper">
	<label class="label" use:scrollToOnClick>
		{translations.name}

		<input
			class="input"
			name="name"
			type="text"
			placeholder={translations.name}
			bind:value={name}
		/>
	</label>

	<label class="label" use:scrollToOnClick>
		{translations.email}

		<input
			class="input"
			name="email"
			type="email"
			placeholder={translations.email}
			bind:value={email}
		/>
	</label>

	<label class="label" for="phone" use:scrollToOnClick>
		{translations.phone}

		<div class="phone-wrapper">
			<select
				bind:value={selectedPhoneCode}
				name="phoneCountryCode"
				class="phone-code-select"
				on:click={(e) => e.stopImmediatePropagation()}
				tabindex="-1"
			>
				{#each phoneCodeOptions as phoneCode}
					<option value={phoneCode.code}>{phoneCode.countryCode}</option>
				{/each}
			</select>

			<input
				id="phone"
				use:imask={phoneOptions}
				class="input phone-input"
				name="phone"
				type="tel"
				placeholder={selectedPhoneCode}
				bind:value={phone}
			/>
		</div>
	</label>
</div>

<style>
	.wrapper {
		display: grid;
		grid: 1fr 1fr 1fr / 1fr;
		grid-gap: 24px;
	}

	.phone-wrapper {
		position: relative;
		border-radius: 6px;
	}

	.phone-input {
		padding-left: 74px;
	}

	.phone-code-select {
		border-radius: 6px;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		padding-left: 12px;
		background-color: transparent;
		border: 0;
		outline: 0;
		font-size: 24px;
		font-weight: bold;
	}
</style>
